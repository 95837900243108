<template>
  <div>
    <!----Section-part----->
    <div class="carloan-section other-insurance">
      <div class="container-fluid">
        <div class="car-loan-heading">
          <h2>Travel Insurance</h2>
          <p>
            When Ypu travel Abroad, whether for study , or leisure trip , Donot
            forget to get the<br />
            travel insurance policy
          </p>
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
    <section class="customers-trust-types included-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps-trust">
              <h3>This Includes</h3>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/cancellation.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">Cover for trip cancellation</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/delays.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Delays Cover for lost, delayed luggage or passport
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/hospitalizationa.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Out/in patient hospitalization coverage
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/contingency.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Contingency cover for personal possessions
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/accident-black.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Accident cover against death/permanent disability
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="types-policy">
      <div class="container">
        <div class="col-md-12">
          <div class="heading-steps inssurance-sec">
            <h4>Home Insurance</h4>
            <p>
              Secure your property from natural and man-made calamity with a
              sturdy HOME INSURANCE.
            </p>
            <h5>These type of policies give protection against,</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/damage.png" alt="icon" />
                </div>
                <h5>Damage by any agent</h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/burglary-breakage.png" alt="icon" />
                </div>
                <h5>Burglary or breakage in house</h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/damage-break.png" alt="icon" />
                </div>
                <h5>Damage & break down of gadgets or appliances</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="third-bottm-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/baggage.png" alt="icon" />
                </div>
                <h5>
                  Loss of baggage during travel Robbery or theft of valuables
                </h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/injury.png" alt="icon" />
                </div>
                <h5>
                  Injury, permanent disability, or death Damage through acts of
                  terrorism
                </h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/public-liability.png" alt="icon" />
                </div>
                <h5>Public liability</h5>
              </div>
            </div>
          </div>
          <div class="view-all-blog other-insurance-btn">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="Insurance"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
export default {
  name: "Otherinsurance",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
  },
};
</script>