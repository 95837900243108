<template class="abc">
  <div id="app">
    <Header />
    <Menu />
    <router-view>
      <Home />
      <Singleproduct />
    </router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/common/Header";
import Menu from "./components/common/Menu";
import Footer from "./components/common/Footer";
import Home from "./components/Home.vue";
import Singleproduct from "./components/Singleproduct";
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "App",
  components: {
    Header,
    Menu,
    Home,
    Singleproduct,
    Footer,
  },
};
</script>



