var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"footer-menu-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"footer-lofo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":"images/footer_logo.png","atl":"logo"}})])],1)]),_c('div',{staticClass:"row footer-sec-two"},[_vm._m(0),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"address"},[_vm._m(1),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/about-us"}},[_vm._v("About Us")])],1),_c('li',[_c('router-link',{attrs:{"to":"/become-dealer"}},[_vm._v("Become a Dealer")])],1),_c('li',[_c('router-link',{attrs:{"to":"/career"}},[_vm._v("Career")])],1),_c('li',[_c('router-link',{attrs:{"to":"/contact-us"}},[_vm._v("Contact Us")])],1)]),_vm._m(2)])]),_vm._m(3)]),_c('div',{staticClass:"col-md-12"})])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"address"},[_c('p',{staticClass:"blocl-footer1"},[_c('span',{staticClass:"map-sec"},[_c('i',{staticClass:"fa fa-map-marker",attrs:{"aria-hidden":"true"}})]),_vm._v(" 50. Old Grain Market Jagraon-142026"),_c('br')]),_c('p',{staticClass:"blocl-footer1"},[_c('span',{staticClass:"map-sec"},[_c('i',{staticClass:"fa fa-map-marker",attrs:{"aria-hidden":"true"}})]),_vm._v(" SCO 29-30, Improvement Trust Building,"),_c('br'),_vm._v(" G.T Road Moga -142001 ")]),_c('a',{staticClass:"first-no",attrs:{"href":"https://wa.me/+919464684879","target":"_blank"}},[_c('span',{staticClass:"map-sec"},[_c('i',{staticClass:"fa fa-whatsapp",attrs:{"aria-hidden":"true"}})]),_vm._v("+91 94-64-6848-79")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('span',[_c('i',{staticClass:"fa fa-link",attrs:{"aria-hidden":"true"}})]),_vm._v("Other Links ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"mailto:contact@justacar.in"}},[_c('span',[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}}),_vm._v("contact@justacar.in")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 addresssection"},[_c('div',{staticClass:"address"},[_c('h3',[_c('span',[_c('i',{staticClass:"fa fa-clock-o",attrs:{"aria-hidden":"true"}})]),_vm._v("Car Store ")]),_c('p',[_vm._v("Mon-Sat: 10:00 am – 5:30 pm"),_c('br')])]),_c('div',{staticClass:"socail-links"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/justacar.in/"}},[_c('i',{staticClass:"fa fa-facebook",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/just_acar/"}},[_c('i',{staticClass:"fa fa-instagram",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/JustACar_in"}},[_c('i',{staticClass:"fa fa-twitter",attrs:{"aria-hidden":"true"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whatsapp-icon"},[_c('a',{staticClass:"left-side",attrs:{"href":"https://wa.me/9464684879","target":"_blank"}},[_c('i',{staticClass:"fa fa-whatsapp",attrs:{"aria-hidden":"true"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signal-icon"},[_c('a',{staticClass:"left-side",attrs:{"href":"tel:94-64-6848-79","target":"_blank"}},[_c('img',{attrs:{"src":"images/signal.png","alt":""}})])])
}]

export { render, staticRenderFns }