var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"carloan-section carinssurancebg"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"car-loan-heading"},[_c('h2',[_vm._v("Car Insurance")]),_vm._m(0),_c('div',{staticClass:"view-all-blog"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onClose(true)}}},[_vm._v("Apply Now")])])])])]),_vm._m(1),_vm._m(2),_c('section',{staticClass:"add-on-covers"},[_c('div',{staticClass:"container"},[_vm._m(3),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"view-all-blog"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onClose(true)}}},[_vm._v("Apply Now")])])])])]),_c('section',{staticClass:"customers-trust-types whyussection"},[_c('div',{staticClass:"container-fluid"},[_vm._m(4),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"view-all-blog"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onClose(true)}}},[_vm._v("Apply Now")])])])])])]),_vm._m(6),_c('Inquiryformmodal',{attrs:{"InquiryType":"Insurance","showTitle":false,"showModal":_vm.showModal,"onClose":_vm.onClose}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Car Insurance is a type of vehicle insurance policy that protects your car & car owner from any risks & "),_c('br'),_vm._v(" damages that may lead to financial losses. It is a contract between the motor vehicle company & a car "),_c('br'),_vm._v(" owner that provides on-road protection against any loss or damages arising due to an accident. It "),_c('br'),_vm._v(" provides financial coverage to the beneficiary of the car policy against any loss or damages arising out of "),_c('br'),_vm._v(" road accidents, third party liabilities, theft, man made calamities, fire or natural disaster. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"here-tip-sec"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"heading-steps inssurance-tex"},[_c('h4',[_vm._v("Buy/Renew Car Insurance")]),_c('p',[_vm._v(" Buying a four wheeler insurance policy is mandatory in India for all cars as per the Motor Vehicle Act.Vehicle insurance "),_c('br'),_vm._v(" companies compensate for the loss or damage caused to the insured vehicle and a third-party from the insured "),_c('br'),_vm._v(" four-wheeler. Here are some of the reasons to buy a new car insurance policy in India: "),_c('br')])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"type-inssrance"},[_c('ul',[_c('li',[_c('span',{staticClass:"check-text"},[_vm._v("It pays for the car damages as a result of a collision, accident, death or natural calamities, which otherwise would need to be paid by the insured")])])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"type-inssrance"},[_c('ul',[_c('li',[_c('span',{staticClass:"check-text"},[_vm._v(" It pays for hospitalization expenses in case of an accident")])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"type-inssrance"},[_c('ul',[_c('li',[_c('span',{staticClass:"check-text"},[_vm._v(" It reduces the financial and legal damages that can arise from a third-party liability or damage")])])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"type-inssrance"},[_c('ul',[_c('li',[_c('span',{staticClass:"check-text"},[_vm._v(" With rider benefits like roadside assistance, zero depreciation the expenses are further reduced")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"types-policy"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"heading-steps inssurance-tex"},[_c('h4',[_vm._v("Types Of Car Insurance Policy In India")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mx-auto"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-work mt-0 text-center"},[_c('div',{staticClass:"work-icon"},[_c('img',{attrs:{"src":"images/Insurance-policy.png","alt":"icon"}})]),_c('h5',[_vm._v("Comprehensive Car Insurance Policy")])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-work mt-0 text-center"},[_c('div',{staticClass:"work-icon"},[_c('img',{attrs:{"src":"images/third-pary.png","alt":"icon"}})]),_c('h5',[_vm._v("Third Party Policy")])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-work mt-0 text-center"},[_c('div',{staticClass:"work-icon"},[_c('img',{attrs:{"src":"images/income.png","alt":"icon"}})]),_c('h5',[_vm._v("Pay As you drive Policy")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"view-all-blog single-btn"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Want to Know more ")])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"cover-text"},[_c('h3',[_vm._v("Add On Covers In Car Insurance Policy")]),_c('ul',[_c('li',[_c('span',[_vm._v(" No Claim Bonus Protection (NCB)")])]),_c('li',[_c('span',[_vm._v(" Zero Depreciation Cover")])]),_c('li',[_c('span',[_vm._v(" Consumable Cover")])]),_c('li',[_c('span',[_vm._v(" Engine Protection Cover")])]),_c('li',[_c('span',[_vm._v(" Key Protection Cover")])]),_c('li',[_c('span',[_vm._v(" Personal Accident Rider Benefit")])]),_c('li',[_c('span',[_vm._v(" Cover For Car Accessories")])]),_c('li',[_c('span',[_vm._v(" Personal Accident Cover")])]),_c('li',[_c('span',[_vm._v(" Loss or Damage oF the Insured Vehicle")])]),_c('li',[_c('span',[_vm._v(" Liability of Third Party")])])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"cover-text"},[_c('h3',[_vm._v("What Is Not Covered Under The Policy")]),_c('ul',[_c('li',[_c('span',[_vm._v(" Loss Or Damage due to Gradual Wear And tear or car or its parts")])]),_c('li',[_c('span',[_vm._v("Loss or Damage , if policy is not in force")])]),_c('li',[_c('span',[_vm._v(" Loss or damage of the vehicle due to intoxication or drugs, alcohol etc.")])]),_c('li',[_c('span',[_vm._v("If policy and Registration are not on the same name( conditions Apply)")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mx-auto"},[_c('div',{staticClass:"heading-steps-trust"},[_c('h3',[_vm._v("Why US")])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/staff.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v(" Dedicated Team of staff for Renewal/Claim Help ")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/disscount.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v(" Discount Upto 80% on your Car Insurance ")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/prmote-services.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v("Prompt Service")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/reminder.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v("Renewal reminders")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10 mx-auto"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/easy2.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v("Easy Endorsments")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/document.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v("Soft Copy OF documents")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"inner-trust-sec"},[_c('div',{staticClass:"img-seccar"},[_c('img',{attrs:{"src":"images/easy.png","alt":""}})]),_c('div',{staticClass:"customer-trust"},[_c('div',{staticClass:"cHead"},[_vm._v(" Easy to choose from different insurance Companies to Choose from ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"our-partners"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mx-auto"},[_c('h3',[_vm._v("Our Partners")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/new-india.png","alt":""}}),_c('h3',[_vm._v("New india Assurance co Ltd")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/bharti-axa.png","alt":""}}),_c('h3',[_vm._v("Bharti-Axa General Insurance co Ltd")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/future-generali.png","alt":""}}),_c('h3',[_vm._v("Future Generali Insurance co, Ltd.")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/sbi.png","alt":""}}),_c('h3',[_vm._v("SBI General Insurance Co Ltd.")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/go-digit-general.png","alt":""}}),_c('h3',[_vm._v("Go Digit General Insurance co. Ltd.")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/reliance.png","alt":""}}),_c('h3',[_vm._v("Reliance General Insurance co ltd.")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"bank-list"},[_c('img',{attrs:{"src":"images/liberty.png","alt":""}}),_c('h3',[_vm._v("Liberty General Insurance co ltd")])])])])])])])])
}]

export { render, staticRenderFns }